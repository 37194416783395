import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { HeaderComponent } from 'components/header/component'
import { EditButtonLight } from 'components/icons/lightedit'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { Row } from 'reactstrap'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { SourceTypes } from 'shared_server_client/constants'
import './_styles.scss'
import * as Types from './types'
import * as Categories from '../../../shared/data-layer/categories'
import Select from 'react-select'

const SourceTypeOptions = [
  { label: SourceTypes.facebook, value: SourceTypes.facebook },
  { label: SourceTypes.rss, value: SourceTypes.rss },
  { label: SourceTypes.twitter, value: SourceTypes.twitter },
]

const defaultCategoryValue = {id: 0, name: '', rank: 0, source_count: 0}

class SourceDetailsComponent extends RasaReactComponent<Types.SourceDetailsProps, Types.SourceDetailsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string

  constructor(p: any) {
    super(p, 'source')
    this.state = {
      allCategories: [],
      allSubCategories: [],
      selectedCategories: [],
      selectedCategory: {...defaultCategoryValue},
      selectedSubCategory: {...defaultCategoryValue, parent: {...defaultCategoryValue}},
      showDuplicationError: false,
      category: '',
      editMode: false,
      id: null,
      url: '',
      isDirty: false,
      isEditing: false,
      isLoading: true,
      isSaving: false,
      name: '',
      type: '',
      sub_category: '',
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.role),
      this.sharedStore.getValue(SharedKeys.params),
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([role, params, activeCommunity]) => {
      this.communityId = activeCommunity.communityId
      if (role === Roles.super_admin) {
        this.initializeRecord(params)
      } else {
        this.goBack()
      }
    })
  }

  private loadContentLibraryData = (): Promise<Types.ContentLibraryData> => {
    return Promise.all([
      Categories.getCategories(2),
      Categories.getSubCategories(1),
    ]).then(([categories, subCategories]) => ({
      categories,
      subCategories,
    }))
  }

  public render = () => {
    const {category, identifier, name, options, sub_category, type} = this.props.data

    return (
      <div className="source-details">
        <div className="go-back" onClick={() => this.goBack()}>
          &lt; &nbsp; Go back
        </div>
        <HeaderComponent
          icon={'sources'}
          title={'Sources'}
          subTitle={this.state.id ? 'Edit Source' : 'Add Source'}
          description={[]}
        />
        {this.state.isLoading ? (
          <Loading size="64" />
        ) : (
          <Row className="info-table">
            <div className="edit-cancel-save">
              {this.state.isEditing ? (
                <p className="saving-button">Saving...</p>
              ) : this.state.editMode ? (
                <p className="edit-button-2">
                  <span
                    className="save"
                    onClick={this.onSave}
                  >
                    Save
                  </span>
                  <span
                    className="cancel"
                    onClick={() => {
                      this.setState({ editMode: !this.state.editMode })
                    }}
                  >
                    Cancel
                  </span>
                </p>
              ) : (
                <p className="edit-button" onClick={this.initializeEdit}>
                  Edit
                  <EditButtonLight />
                </p>
              )}
            </div>
            <div className="properties-and-values">
              <div className="properties">
                <p
                  className={`property${
                    this.state.editMode ? ' edit-mode' : ''
                  }`}
                >
                  Name:
                </p>
                <p
                  className={`property${
                    this.state.editMode ? ' edit-mode' : ''
                  }`}
                >
                  URL:
                </p>
                <p
                  className={`property${
                    this.state.editMode ? ' edit-mode' : ''
                  }`}
                >
                  Source Type:
                </p>
                <p
                  className={`property${
                    this.state.editMode ? ' edit-mode' : ''
                  }`}
                >
                  Category:
                </p>
                {
                  !this.state.editMode && <p
                    className={`property${
                      this.state.editMode ? ' edit-mode' : ''
                    }`}
                  >
                    Sub Category:
                  </p>
                }
              </div>
              <div className="values">
                {this.state.editMode ? (
                  <div className="edit-details">
                    <p>
                      <input
                        type="text"
                        className={'formControl'}
                        value={this.state.name}
                        onChange={(e) => {
                          e.preventDefault()
                          this.setState({name: e.target.value})
                        }}
                      />
                    </p>
                    <p>
                      <input
                        type="text"
                        className={'formControl'}
                        value={this.state.url}
                        onChange={(e) => {
                          e.preventDefault()
                          this.setState({url: e.target.value})
                        }}
                      />
                    </p>
                    <p>
                      <Select
                        onChange={(e) => this.setState({type: e.value})}
                        value={{
                          label: this.state.type ? this.state.type : 'Select',
                          value: this.state.type ? this.state.type : ''}}
                        options={SourceTypeOptions}/>
                    </p>
                    <div className={'categoryHolder'}>
                      <div className={'selectColBox'}>
                        <Select
                          onChange={this.onCategoryChange}
                          value={{
                            label: this.state.selectedCategory.name ? this.state.selectedCategory.name : 'Select Category',
                            value: this.state.selectedCategory.id
                          }}
                          options={this.state.allCategories.map((x) => {
                            return {
                              label: x.name,
                              value: x.id,
                            }
                          })}
                        />
                      </div>
                      <div className={'selectColBox'}>
                        <Select
                          onChange={this.onSubCategoryChange}
                          value={{
                            label: this.state.selectedSubCategory.name ? this.state.selectedSubCategory.name : 'Select Sub Category',
                            value: this.state.selectedSubCategory.id
                          }}
                          options={this.state.allSubCategories.filter((x) => x.parent.id === this.state.selectedCategory.id)
                            .map((x) => {
                              return {
                                label: x.name,
                                value: x.id,
                              }
                            })}/>
                      </div>
                      <div>
                        <button className={'btn btn-small'} onClick={this.updateSelectedCategories}>Add</button>
                      </div>
                    </div>
                    {this.state.showDuplicationError === true &&
                      <div className={'text-danger categoryError'}>You're trying to add same category/subcategory</div>}
                    {this.state.selectedCategories.length > 0 && <div>
                      <h4 className={'categoryTitle'}>Selected Categories</h4>
                      <ul className={'categoryList'}>
                        {
                          this.state.selectedCategories
                            .map((item: any) => <li key={item.id}>
                              <i className="fa fa-trash fa-2 clickable-item"
                                 onClick={() => this.removeItemFromSelectedCategories(item)}/>&nbsp;&nbsp;
                              {
                                item.parent ?
                                  (`${item.parent.name} (${item.parent.source_count}) / ${item.name} (${item.source_count})`)
                                  : `${item.name} (${item.source_count}) `
                              }
                            </li>)
                        }
                      </ul>
                    </div>}
                  </div>
                ) : (
                  <div className="details-view">
                    <p>{name || '-'}</p>
                    <p>
                      {type
                        ? type.toLowerCase() !==
                        SourceTypes.facebook.toLocaleLowerCase()
                          ? identifier || '-'
                          : options && JSON.parse(options).url
                            ? JSON.parse(options).url
                            : '-'
                        : '-'}
                    </p>
                    <p>{type || '-'}</p>
                    <p>{category || '-'}</p>
                    <p>{sub_category || '-'}</p>
                  </div>
                )}
              </div>
            </div>
          </Row>
        )}
      </div>
    )
  }

  private goBack = () => this.props.push(DashboardMenuOption.adminContentLibrary)

  private initializeRecord(params: any) {
    this.loadContentLibraryData()
      .then((response: Types.ContentLibraryData) => {
        this.setState({
          allCategories: response.categories,
          allSubCategories: response.subCategories,
        })

        if (params.id) {
          this.setState({id: params.id})
          this.loadRecord(this.communityId, params.id)
            .then(() => {
              const categoryIds = this.props.data.category_ids ?
                this.props.data.category_ids.split('|') : []
              const subCategoryIds = this.props.data.sub_category_ids ?
                this.props.data.sub_category_ids.split('|') : []
              const selectedCategories = []
              categoryIds.map((x) => {
                const selectedCategory = this.state.allCategories
                  .find(category => category.id === parseInt(x, 10))
                selectedCategories.push(selectedCategory)
              })
              subCategoryIds.map((x) => {
                const selectedSubCategory = this.state.allSubCategories
                  .find(subCategory => subCategory.id === parseInt(x, 10))
                const selectedCategory = selectedCategories.find((selCategory) => selCategory.id === selectedSubCategory.parent.id)
                const indexOf = selectedCategories.indexOf(selectedCategory)

                if (indexOf > -1) {
                  selectedSubCategory.parent.source_count = selectedCategory.source_count
                  selectedCategories[indexOf] = selectedSubCategory
                }
              })
              this.setState({
                selectedCategories
              })

              this.setState({isLoading: false})
            })
        } else {
          this.loadRecord(this.communityId, null).then(() => {
            this.props.propertiesChanged({
              category: '',
              id: null,
              identifier: '',
              name: '',
              options: '',
              type: '',
              sub_category: '',
            })
            this.setState({editMode: true, isLoading: false})
          })
        }
      })
  }

  private initializeEdit = () => {
    if (this.state.editMode) {
      this.setState({
        category: '',
        editMode: false,
        name: '',
        sub_category: '',
        type: '',
        url: '',
      })
    } else {
      const {category, identifier, name, options, sub_category, type} = this.props.data
      const url = type.toLowerCase() !== SourceTypes.facebook.toLowerCase()
        ? identifier
        : options ? JSON.parse(options).url : ''
      this.setState({
        category,
        editMode: true,
        url,
        name,
        type,
        sub_category,
      })
    }
  }

  private onCategoryChange = (e) => {
    const selectedCategory = this.state.allCategories
      .find(x => x.id === e.value)
    this.setState({
      selectedCategory
    })
  }

  private onSubCategoryChange = (e) => {
    const selectedSubCategory = this.state.allSubCategories
      .find(x => x.id === e.value)
    this.setState({
      selectedSubCategory
    })
  }

  private updateSelectedCategories = () => {
    if (this.state.selectedCategory.id === 0) {
      return
    }

    this.setState({
      showDuplicationError: false
    })

    let pushedItem = this.state.selectedCategory
    const selectedCategories = [...this.state.selectedCategories]
    selectedCategories.push(pushedItem)

    if (this.state.selectedSubCategory.id !== 0) {
      const selectedSubCategory = {...this.state.selectedSubCategory}
      const selectedCategory = selectedCategories.pop()
      selectedSubCategory.parent.source_count = selectedCategory.source_count
      pushedItem = selectedSubCategory
      selectedCategories.push(pushedItem)
    }

    // update state only when selected item is not in list
    if (!this.state.selectedCategories.some((x) => x.id === pushedItem.id)) {
      this.setState({
        selectedCategories,
        selectedCategory: {...defaultCategoryValue},
        selectedSubCategory: {...defaultCategoryValue, parent: {...defaultCategoryValue}},
      })
    } else {
      this.setState({
        showDuplicationError: true
      })
    }
  }

  private removeItemFromSelectedCategories = (item) => {
    this.setState({
      selectedCategories: this.state.selectedCategories
        .filter((x) => x.id !== item.id)
    })
  }

  private onSave = () => {
    this.setState({ isEditing: true }, () => {
      if (
        this.state.type.toLowerCase() !==
        SourceTypes.facebook.toLowerCase()
      ) {
        this.propertyChanged('identifier', this.state.url)
      } else {
        this.propertyChanged(
          'options',
          JSON.stringify({ url: this.state.url }),
        )
      }

      const nCategory = this.state.selectedCategories
        .map((x) => x.parent ? x.parent.name : x.name )
        .join('|')
      const nSubCategory = this.state.selectedCategories.filter((x) => x.parent)
        .map((x) => x.name)
        .join('|')
      this.propertyChanged('name', this.state.name)
      this.propertyChanged('category', nCategory)
      this.propertyChanged('sub_category', nSubCategory)
      this.propertyChanged('type', this.state.type)

      this.saveRecord(this.props.data.id)
        .then(() => {
          this.setState({ isEditing: false, editMode: false })
        })
        .catch(() =>
          this.setState({ isEditing: false, editMode: false }),
        )
    })
  }
}

export const ContentPoolSourceDetails = GenericRedux.registerNewNestedComponent(
  SourceDetailsComponent, 'source_details')
