import {
  GridColumn as Column,
} from '@progress/kendo-react-grid'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaDataGrid  } from 'generic/rasaDataGrid'
import React, { Component } from 'react'
import * as ActiveSources from '../../../../shared/data-layer/active_sources'
import * as Categories from '../../../../shared/data-layer/categories'
import { CategoriesCell, CopyToClipboardCell, TitleCell } from '../../kendocells'
import './_styles.scss'
import { SourceContentLibraryModal } from './modal'
import * as Types from './types'

export class ContentLibrarySources extends Component<Types.Props, Types.State> {
  public static contextType = RasaContext

  constructor(props: Types.Props) {
    super(props)
    this.state = {
      categories: [],
      isLoading: true,
      skip: 0,
      sort: [],
      sources: [],
      subCategories: [],
      take: 25,
    }
  }

  public componentDidMount = () => {
    this.loadContentLibraryData()
      .then((response: Types.ContentLibraryData) => {
        this.setState({
          categories: response.categories,
          isLoading: false,
          subCategories: response.subCategories,
        })
      })
  }

  public render() {
    return <div className="content-library-source-wrapper">
      <HeaderComponent
        icon={'addArticle'}
        title={'ADD SOURCES'}
        subTitle={'Add sources to the content library'}
        description={[
          'Review any existing active sources and choose to categorize them for the content library',
        ]}/>
    {this.state.isLoading ?
      <Loading size="64"/>
    :
      <div>
        <RasaDataGrid
            // Rasa Properties for entity, datasetName etc
            entityName="activeSource"
            datasetName="activeSources"
            editDefaultState={false}
            // events
            onDataReceived={this.dataReceived}
            sortable={false}
            pageable={true}
            pageSize={25}
            data={[]}>
          <Column field="full_category" title="Category" width={350} cell={CategoriesCell}/>
          <Column field="article_count" title="Count" width={100}/>
          <Column field="name" title="Name" width={250} cell={this.ClickableTitleCell}/>
          <Column field="identifier" title="URL" cell={CopyToClipboardCell}/>
        </RasaDataGrid>
      </div>
      }
      <SourceContentLibraryModal
        data={this.props.modals}
        categories={this.state.categories}
        subCategories={this.state.subCategories}
        closeModal={this.props.closeModal}/>
    </div>
  }

  protected dataReceived = (data) => {
		if (data.data) {
			this.setState({
				sources: data.data,
			})
		}
	}

  private loadContentLibraryData = (): Promise<Types.ContentLibraryData> => {
    return Promise.all([
      Categories.getCategories(2),
      Categories.getSubCategories(1),
    ]).then(([categories, subCategories]) => ({
      categories,
      subCategories,
    }))
  }

  private editSource = (e: ActiveSources.ActiveSource) => {
    this.props.openModal(SourceContentLibraryModal.key, { ...e })
  }

  private ClickableTitleCell = (props: any) => {
    return <TitleCell {...props} onClick={(e) => this.editSource(e)}/>
  }

}

export const ContentLibrarySourcesComponent = GenericRedux.registerNewComponentWithModals<ActiveSources.ActiveSource>(
  ContentLibrarySources,
  'sourcesContentLibrary',
  [SourceContentLibraryModal.key],
)
