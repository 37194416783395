import { GridCellProps, GridRowClickEvent } from '@progress/kendo-react-grid'
import { showAppCue } from 'components/app-cues/actions'
import { APP_CUE_TYPE } from 'components/app-cues/constants'
import { DropdownOption } from 'components/dropdown/component'
import { GeneratedSummaryModalComponent } from 'components/generate-text'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { MenuItem, TabMenu } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import { isFalsey, isTruthy } from 'generic/utility'
import { groupBy, isEmpty, keyBy } from 'lodash'
import React, {Component} from 'react'
import { Button } from 'reactstrap'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ModalSize } from 'shared/modals/constants'
import { DEFAULT_COMMUNITY_PARTNER_CODES, NORMAL_BOOSTED_BOOST_LEVEL, SourceTypes, SUPER_BOOSTED_BOOST_LEVEL } from 'shared_server_client/constants'
import { UpcomingIssue } from 'shared_server_client/types/schedule'
import { EditableDropdownCell, EditCellProps} from '../../generic/editCell'
import * as GenericRedux from '../../generic/genericRedux'
import {ChangeEvent, PagingSource, RasaDataGrid} from '../../generic/rasaDataGrid'
import * as Columns from '../grid/columns'
import { AddEditArticleModal } from './addEditArticleModal'
import * as Constants from './constants'
import {
  ActiveCell,
  ArticleLink,
  DateTimeCell,
  ImageCell,
  SourceCell,
  TitleCell,
  TooltipCellHeader,
} from './kendocells'
import { UpcomingArticlesModal } from './modals'
import './styles.css'
import { Article, DisplayArticle, EmptyDisplayArticle } from './types'
import { imageIsTooSmall } from './utility/utils'
import { ImageGalleryModal } from '../common/image-gallery/modal'
type ReservedArticleProps = GenericRedux.AllComponentPropsWithModal<DisplayArticle>

interface SelectedSourceHash {
  [name: string]: boolean,
}

interface ArticleHash {
  [id: number]: DisplayArticle
}

interface ReservedArticlesState extends Columns.SizableColumnState {
  activeArticlesCount: number,
  activeToggleCount: number,
  articles: DisplayArticle[],
  articleHash: ArticleHash,
  articlesToDisplay: DisplayArticle[],
  columns: Columns.SizableColumns,
  currentRole: string,
  communityId: string,
  emailLayoutId: number,
  emailTemplate: string,
  fixedWidth: number,
  forceReload: boolean,
  initialNextIssue: UpcomingIssue,
  isLoading: boolean,
  isSuperUser: boolean
  minimumArticlesCount: number,
  search: string,
  sections: any[],
  selected?: SelectedSourceHash,
  selectedFilterOption: DropdownOption,
  selectedSection: string,
  selectedSections: string[],
  selectedSources: string[],
  sources: any[],
  updatedArticles: any[],
}

enum ArticleSections {
  active = 'article_active',
  used = 'article_used',
}

class ReservedArticlesComponent extends Component<ReservedArticleProps, ReservedArticlesState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: ReservedArticleProps) {
    super(props);
    this.state = {
      activeArticlesCount: null,
      activeToggleCount: 0,
      articles: [],
      articleHash: {},
      columns: null,
      communityId: '',
      emailLayoutId: null,
      fixedWidth: 0,
      forceReload: false,
      initialNextIssue: null,
      isLoading: false,
      isSuperUser: false,
      minimumArticlesCount: null,
      panelWidth: 0,
      search: '',
      sections: [],
      selected: {
        All: true,
        [SourceTypes.twitter]: false,
        [SourceTypes.facebook]: false,
        [SourceTypes.rss]: false,
        [SourceTypes.scraper]: false,
        [SourceTypes.native]: false,
        [SourceTypes.youtube]: false,
      },
      selectedFilterOption: Constants.AllFilters[0],
      selectedSection: ArticleSections.active,
      emailTemplate: '',
      articlesToDisplay: [],
      currentRole: '',
      sources: [],
      selectedSections: [],
      selectedSources: [],
      updatedArticles: [],
    }
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.emailTemplate),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.role),
    ])
    .then(([activeCommunity, emailTemplate, person, role]) => {
      const initialNextIssue = activeCommunity.data.schedule_is_active ? activeCommunity.nextIssue : null
      // Need to invert the structure!
      this.setState({
        columns: new Columns.SizableColumns(this.getColumns()),
        communityId: activeCommunity.communityId,
        currentRole: activeCommunity.role,
        emailLayoutId: activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id,
        emailTemplate: emailTemplate.toString(),
        initialNextIssue,
        isSuperUser: role === Roles.super_admin,
        panelWidth: window.innerWidth - Columns.LEFT_PANEL_DEFAULT_WIDTH,
      })
      this.getSections(activeCommunity.communityId)
      this.getSources(activeCommunity.communityId)
      }).then(() => this.setMinimumArticlesCount())
  }

  public render() {
    return (
      <div className="reserved-articles">
        <UpcomingArticlesModal
          data={this.props.modals}
          closeModal={this.props.closeModal}
          title="WARNING"/>
        <AddEditArticleModal
          data={this.props.modals}
          allowSegment={true}
          defaultSegmentCode={DEFAULT_COMMUNITY_PARTNER_CODES.ALL}
          allowGeneratingViaChatGPT={true}
          isScheduled={false}
          updateModal={this.props.updateModal}
          openModal={this.props.openModal}
          saveModal={this.props.saveModal}
          closeModal={this.props.closeModal}
          afterSave={this.postEditArticle}
          title="Edit Article"
          size={ModalSize.Large} />
        <div className="header-container">
          <HeaderComponent
            title={'ARTICLES'}
            subTitle={'Reserve'}
            description={['Articles that will be included in an upcoming newsletter. One article from this pool will be included in every',
            'subscribers newsletter for an upcoming send. Once sent, it will be moved to used.']}
          />
          <div className="add-new-article-and-next-newsletter-wrapper">
            <Button className="add-button add-new-article " onClick={() =>
            this.props.openModal(AddEditArticleModal.key, {
              communityId: this.state.communityId,
              isReserved: true,
            })}>
              <span className="button-text">Add Article</span>
            </Button>
          </div>
        </div>
        {this.state.isLoading
        ? <div className="main-loading"><Loading size="64"/></div>
        : <div>
            <div>
              <TabMenu menus={this.articleMenus()}
                        selectedItemKey={this.state.selectedSection}
                        onSelect={(item: MenuItem) => {
                          this.setState({
                            selectedSection: item.key,
                            columns: new Columns.SizableColumns(this.getColumns(item.key)),
                          })
                        }}/>
            </div>
            <RasaDataGrid<DisplayArticle>
                // Rasa Properties for entity, datasetName etc
                entityName="communityArticle"
                datasetName="communityReservedArticles"
                editDefaultState={true}
                forceReload={this.state.forceReload}
                // events
                onDataReceived={this.dataReceived}
                normalizeDataOnChange={this.normalizeBoost}
                onRowClick={(p: GridRowClickEvent) =>  this.editPost(p.dataItem)}
                sortable={true}
                pageable={true}
                pageSize={50}
                pagingSource={PagingSource.local}
                gridFilter={this.filterData}
                gridTransform={this.transformData}
                data={[]}>
              {this.state.columns && this.state.columns.buildColumns(this.state.panelWidth, this.state.currentRole)}
            </RasaDataGrid>
          </div>}
      </div>
    )
  }

  private forceReload = () => {
    this.setState({forceReload: true}, () => this.setState({forceReload: false}))
  }

  private postEditArticle = (p: any) => {
    if (p.typeId) {
      const updatedArticles = this.state.updatedArticles.map((x) => {
        if (x.id === p.typeId) {
          const selectedSection = this.state.sections.find((section) => section.category_type_id === p.categoryTypeId)
          return {
            ...x,
            category_type: p.contentCategory,
            title: p.title,
            description: p.description,
            section_name: selectedSection ? selectedSection.name : null,
            custom_tags: p.customTags,
            hosted_image_url: p.hostedImage ? p.hostedImage.url : x.hosted_image_url,
            site_name: p.publisher,
          }
        }
        return x
      })
      this.setState({
        updatedArticles,
      })
    } else {
      this.forceReload()
    }
  }

  private setMinimumArticlesCount = () => {
    this.context.entityMetadata.getEntityObject(
            'email_layout', this.state.communityId, this.state.emailLayoutId)
            .then((emailEntityObject: any) => {
              const minimumArticlesCount = emailEntityObject._data.minimum_newsbrief_count || 4
              this.setState({
                minimumArticlesCount: Number(minimumArticlesCount),
              })
            })
  }

  private handleResize = () => {
    this.setState({
      panelWidth: window.innerWidth - Columns.LEFT_PANEL_DEFAULT_WIDTH,
    })
  }

  private ArticleEditCell = (props: EditCellProps) => {
    return !props.dataItem.last_issue_id && props.dataItem.is_eligible ?
      <EditableDropdownCell {...props}
        onReserved={this.onReserved}
        canMarkReserved={true} /> : <td></td>
  }

  private onReserved = (props: EditCellProps, event) => {
    const newReserved = !props.dataItem.is_reserved
    props.onChange({
      dataItem: props.dataItem,
      syntheticEvent: event,
      field: 'is_reserved',
      value: newReserved,
    })
    this.editArticle(props.dataItem, {
      is_reserved: newReserved,
    })
  }

  // private GridReservedCell = (props: any) => {
  //   return <td><ReservedCell onToggle={this.toggleReserve} hideLabel="true" {...props} /></td>
  // }

  private ImageCell = (props: EditCellProps) => {
    return <ImageCell {...props}
                      emailTemplate={this.state.emailTemplate}
                      warningMessage={Constants.IMAGE_MAY_BE_TOO_SMALL_TOOLTIP}/>
  }

  private getNextIssue(): UpcomingIssue {
    return this.context.store.getState().schedule.data.nextIssue || this.state.initialNextIssue
  }

  private isReservedArticle(article: Article): boolean {
    return isFalsey(article.last_issue_id) && isTruthy(article.is_reserved)
  }

  private articleMenus = (): MenuItem[] => {
    const filterdData = this.state.articles.filter((a) => this.isArticleMatchingSearch(a))
    const reservedArticles = groupBy(filterdData, this.isReservedArticle)
    const byActive = groupBy(reservedArticles.true, 'is_eligible')
    const menus = [
      {
        name: 'Active (' + (byActive[1] || []).length + ')',
        key: ArticleSections.active,
        component: null,
      },
      {
        name: 'Used (' + (reservedArticles.false || []).length + ')',
        key: ArticleSections.used,
        component: null,
      },
    ]
    return menus
  }

  // private setSearch = (s: string) => {
  //   this.setState({
  //     search: s,
  //   })
  // }

  private normalizeBoost = (item: any, event: ChangeEvent) => {
    if (event.field === 'boost_level' &&
        event.value === SUPER_BOOSTED_BOOST_LEVEL &&
        item.boost_level === SUPER_BOOSTED_BOOST_LEVEL) {
      return {
        ...item,
        boost_level: NORMAL_BOOSTED_BOOST_LEVEL,
      }
    } else {
      return item
    }
  }

  private ClickableTitleCell = (props: any) => {
    return <TitleCell {...props} onClick={(e) => this.editPost(e)}/>
  }

  private ActiveCellTooltip = (props: any) => {
    const tooltipText = 'Use this toggle to remove an article that you don\'t want to be considered'
    return <TooltipCellHeader {...props} tooltip={tooltipText}/>
  }

  private dataReceived = (data) => {
    const nextIssue: UpcomingIssue = this.getNextIssue()
    if (data.data) {
      // Force throwing away any reserved articles that are after our cutoff window.
      // We don't want them at all.
      const articlesToDisplay = this.getArticlesToDisplay(data.data, nextIssue)
      this.setState({
        activeArticlesCount: articlesToDisplay.filter((a) => !!a.is_active).length,
        articles: articlesToDisplay,
        articlesToDisplay,
        articleHash: keyBy(articlesToDisplay, 'id'),
        updatedArticles: articlesToDisplay,
      })
      if (articlesToDisplay.filter((r) => r.is_active).length >= 100) {
        this.context.store.dispatch(showAppCue(APP_CUE_TYPE.ARTICLES_100))
      }
    }
  }

  private getArticlesToDisplay = (allArticles: DisplayArticle[], nextIssue: UpcomingIssue): DisplayArticle[] => {
    return allArticles.filter((article) => (isEmpty(nextIssue) || Date.parse(article.created) <= Date.parse(nextIssue.cutoff)))
  }

  private editPost = (p: any) => {
    this.props.openModal(AddEditArticleModal.key, this.normalizeItem(p))
  }

  private normalizeItem = (p: any) => {
    if (!p) {
      return {
        communityId: this.state.communityId,
      }
    }
    const normalizedItem = {
      ...p,
      typeId: p.id,
      communityId: this.state.communityId,
      publisher: p.site_name,
      image: p.hosted_image_url,
      contentCategory: p.category_type,
      customTags: p.custom_tags,
      nlpTags: p.nlp_tags,
      sourceTags: p.source_tags,
    }
    if (p.hosted_image_url) {
      normalizedItem.hostedImage = {
        url: p.hosted_image_url,
        width: p.image_width,
        height: p.image_height,
      }
    }
    return normalizedItem
  }

  private isArticleMatchingSearch = (a: DisplayArticle): boolean => {
    if (this.state.selectedFilterOption.key === Constants.FilterOption.NO_IMAGE
        && a.hosted_image_url) {
      return false
    } else if (this.state.selectedFilterOption.key === Constants.FilterOption.IMAGE_WARNING && a.hosted_image_url
      && imageIsTooSmall(a.image_height, a.image_width, this.state.emailTemplate)) {
      return false
    } else if (this.state.selectedSources.length > 0 && !this.state.selectedSources.includes(a.source_name)) {
      return false
    } else if (this.state.selectedSections.length > 0) {
      // note that selectedSections is an array
      // combination could be: 1 section or multiple sections
      // no section and one of the section or multiple sections
      const selectedSections = [...this.state.selectedSections]
      const noSectionIndex = selectedSections.indexOf(Constants.NO_SECTION)
      if (noSectionIndex > -1) {
        selectedSections[noSectionIndex] = null
      }
      if (!selectedSections.includes(a.section_name)) {
        return false
      }
    }
    if (this.state.search.length >= 3) {
      const searchTerm = this.state.search.toLowerCase()
      if (
        (a.title || '').toLowerCase().includes(searchTerm) ||
        (a.description || '').toLowerCase().includes(searchTerm) ||
        (a.url || '').toLowerCase().includes(searchTerm) ||
        (a.source_name || '').toLowerCase().includes(searchTerm)) {
          return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
  private transformData = (a: DisplayArticle): DisplayArticle => {
    return this.state.updatedArticles.filter((x) => x.id === a.id)[0]
  }
  private filterData = (a: DisplayArticle): boolean => {
    const nextIssue: UpcomingIssue = this.getNextIssue()
    if (!this.isArticleMatchingSearch(a)) {
      return false
    }

    // We want to filter active based on the state when we loaded the page
    // so they don't toggle back and forth between the pages.
    // So - don't test a (the article we have that's *live* in the grid)
    // but instead test the corresponding article within our state - the articles that
    // were found when we loaded the page.
    const stateArticle = this.articleInState(a) || a

    if ((this.state.selectedSection === ArticleSections.active) &&
      stateArticle.last_issue_id) {
      return false
    } else if ((this.state.selectedSection === ArticleSections.used) &&
      !stateArticle.last_issue_id) {
      return false
    }

    if (!isEmpty(nextIssue)) {
        // Only include boosted/lead posts or posts from before the cutoff
        return (
          (a.subscription_type === 'Lead') ||
          (nextIssue.cutoff && Date.parse(a.created) <= Date.parse(nextIssue.cutoff))
        )
    } else {
      return true
    }
  }

  private articleInState = (gridArticle: DisplayArticle): DisplayArticle => {
    return this.state.articleHash[gridArticle.id]
  }

  private editArticle = (dataItem: any, change: any) => {
    const updatedArticles = this.state.updatedArticles.map((x) => {
      if (x.id === dataItem.id) {
        return {
          ...x,
          ...change,
        }
      } else {
        return x
      }
    })
    this.setState({
      updatedArticles,
    })
  }

  private toggleArticle = (dataItem: any, newIsEligible: boolean) => {
    const change = {
      is_eligible:  (newIsEligible ? 1 : 0),
    }
    this.editArticle(dataItem, change)
    if (this.state.activeArticlesCount === this.state.minimumArticlesCount && !newIsEligible) {
      this.props.openModal(UpcomingArticlesModal.key, {
        minimumArticlesCount: this.state.minimumArticlesCount,
      })
    }
    const newActiveArticlesCount = newIsEligible ? this.state.activeArticlesCount + 1 :
      this.state.activeArticlesCount - 1
    this.setState({
      activeToggleCount: newIsEligible ? this.state.activeToggleCount + 1 : this.state.activeToggleCount - 1,
      activeArticlesCount: newActiveArticlesCount,
    })
  }

  private ArticleActiveCell = (props: GridCellProps) => {
    return <ActiveCell fieldName="is_eligible" {...props} onToggle={this.toggleArticle}/>
  }

  private getSections = (community) => {
    new Dataset()
    .loadCommunityDataset('sections', community)
    .then((response) => {
      const  avlSections = [...response[0], {name: Constants.NO_SECTION}]
      return this.setState({sections: avlSections || []}, () => {
        this.setState({
          columns: new Columns.SizableColumns(this.getColumns()),
        })
      })
    })
  }

  private getColumns = (section: any = ArticleSections.active): Columns.SizableColumn[] => {
    const defaultColumns = [{
      cell: this.ImageCell,
      field: '',
      minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
      sortable: false,
      title: 'Image',
      width: 130,
    },
    {
      cell: this.ClickableTitleCell,
      field: 'title',
      minWidth: 300,
      title: 'Title',
    },
    {
      cell: ArticleLink,
      sortable: false,
      title: '',
      width: 35,
    },
    {
      cell: SourceCell,
      field: 'site_name',
      title: 'Source',
      width: 150,
    },
    {
      cell: DateTimeCell,
      field: 'created',
      minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
      title: 'Published',
      width: 150,
    },
    {
      field: 'section_name',
      title: 'Section',
      width: 100,
    },
    {
      cell: this.ArticleEditCell,
      field: '',
      title: '',
      width: 50,
    }]
    switch (section) {
      case ArticleSections.active:
        return [
          {
            cell: this.ArticleActiveCell,
            field: 'is_eligible',
            headerCell: this.ActiveCellTooltip,
            title: 'Eligible',
            width: 100,
          },
          ...defaultColumns,
        ]
      default:
        return defaultColumns
    }
  }
  private getSources = (communityId) => {
    return new Dataset()
    .loadCommunityDataset('communitySources', communityId)
    .then((response) => {
     this.setState({sources: response[0] || []})
    })
  }
}

export const ContentPoolReservedArticles = GenericRedux.registerNewComponentWithModals<DisplayArticle>(
  ReservedArticlesComponent,
  'reserved_articles',
  [UpcomingArticlesModal.key, AddEditArticleModal.key, GeneratedSummaryModalComponent.key, ImageGalleryModal.key],
  EmptyDisplayArticle,
  )
